/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProfileImg from "../images/profile-pic.jpg"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="">
      <div className="group block my-5 flex-shrink-0">
        <div className="flex items-center">
          <div>
            <img src={ProfileImg} alt="Gil Perez" className="inline-block h-9 w-9 rounded-full" />
          </div>
            {author?.name && (
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{author.name}</p>
                <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{author?.summary || null}</p>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default Bio
